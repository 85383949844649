import service from '@/utils/request'

const url = '/api/v1/south'

export const getSouthInfo = () => {
  return service({
    url,
    method: 'get',
  })
}

export const changeSouthInfo = (data) => {
  return service({
    url,
    method: 'post',
    data,
  })
}
