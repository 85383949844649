<template>
  <div style="height: 800px; display: flex; justify-content: left; align-items: center;">
    <el-form  :model="form"  label-width="auto" style="width: 600px" size="large">
      <el-form-item label="使能">
        <el-switch v-model="southInfo.enable" size="large" :disabled="editDisabled"/>
      </el-form-item>
      <el-form-item label="服务器地址">
        <el-input :disabled="editDisabled" v-model="southInfo.server" placeholder="Approved by" /> 
      </el-form-item>
      <el-form-item label="服务器端口">
        <el-input :disabled="editDisabled" v-model="southInfo.port" /> 
      </el-form-item>
      <el-form-item label="Token">
        <el-input :disabled="editDisabled" v-model="southInfo.token" /> 
      </el-form-item>
      <el-form-item label="获取数据的时间间隔">
        <el-input :disabled="editDisabled" v-model="southInfo.interval" /> 
      </el-form-item>
        
      <el-form-item>
        <el-button v-if="editDisabled" type="primary" @click="editEnable">修改南向配置</el-button>
        <div v-else>
          <el-button type="danger" @click="confirmEdit">确认</el-button>
          <el-button @click="cancelEdit">取消</el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script setup>
import {ref} from "vue"
import {getSouthInfo, changeSouthInfo} from "@/api/south";

const southInfo = ref({})

let backup = {}

const editDisabled = ref(true)

function editEnable() {
  backup = {...southInfo.value}
  editDisabled.value = false
}

function confirmEdit() {
  changeSouthInfo(southInfo.value).then(()=>{
    getData()
    editDisabled.value = true
  })
}

function cancelEdit() {
  southInfo.value = {...backup}
  editDisabled.value = true
}

function toClipboard(text) {
  navigator.clipboard.writeText(text)
}



function getData() {
  getSouthInfo().then(r => {
    if (r.code === 0) {
      southInfo.value = r.data
    }
  })


}

getData()

</script>

<style scoped>

</style>